#root {
  height: 100vh;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-ms-reveal {
  display: none;
}

input::-ms-clear {
  display: none;
}

.wNWpza_AccordionDetailsRoot {
  padding: 0;
}
/*# sourceMappingURL=index.abc47bc1.css.map */
